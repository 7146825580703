
import { Options, Vue } from 'vue-class-component';
import { AuthClient } from '@/services/Services';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class ResetPassword extends Vue {
    
    username: string = "";

    resetPassword(){
        AuthClient.resetPassword(this.username)
        .then( x => {
            var message = LocalizationServices.getLocalizedValue("app_tiabbiamoinviatolanuovapassword", "Ti abbiamo inviato una mail contenente la nuova password!");
            this.$opModal.show(ModalSuccessError,
            {
                message: message
            })
        })
        .catch( err => {
            var message = LocalizationServices.getLocalizedValue("app_utentenontrovato", "Utente non trovato!");
            this.$opModal.show(ModalSuccessError, 
            {
                isError: true,
                message: message
            })
        });
    }

    doError(){

    }
}

